import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/site/src/components/episodeLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Sammanfattning`}</h2>
    <p>{`I säsongsavslutningen pratar vi om nya imponerande ChatGPT, eSam, dSam och Matrix hos myndigheter, Mozillas egna metaverse-initiativ, ett öppet alternativ till spelet Civilization och slutligen hur Linux slår igenom i Indien.`}</p>
    <h2>{`Nyheter`}</h2>
    <ul>
      <li parentName="ul">{`Kort om ChatGPT: `}<a parentName="li" {...{
          "href": "https://openai.com/blog/chatgpt/"
        }}>{`https://openai.com/blog/chatgpt/`}</a></li>
      <li parentName="ul">{`eSam och dSam förespråkar Matrix:`}<ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://element.io/blog/dsam-och-esam-forordar-matrix-for-saker-och-federerad-kommunikation-inom-sveriges-offentliga-sektor/"
            }}>{`https://element.io/blog/dsam-och-esam-forordar-matrix-for-saker-och-federerad-kommunikation-inom-sveriges-offentliga-sektor/`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.esamverka.se"
            }}>{`https://www.esamverka.se`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://mastodon.se/@samuel/109443017261026562"
            }}>{`https://mastodon.se/@samuel/109443017261026562`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.esamverka.se/download/18.a9fb3aa17ce4b6615c5e891/1637154585411/Digital%20samarbetsplattform%20f%C3%B6r%20offentlig%20sektor.pdf"
            }}>{`https://www.esamverka.se/download/18.a9fb3aa17ce4b6615c5e891/1637154585411/Digital%20samarbetsplattform%20f%C3%B6r%20offentlig%20sektor.pdf`}</a></li>
        </ul></li>
      <li parentName="ul">{`Mozilla sugna på eget Metaverse: `}<ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://techcrunch.com/2022/12/01/mozilla-acquires-active-replica-to-build-on-its-metaverse-vision/"
            }}>{`https://techcrunch.com/2022/12/01/mozilla-acquires-active-replica-to-build-on-its-metaverse-vision/`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://hubs.mozilla.com/"
            }}>{`https://hubs.mozilla.com/`}</a></li>
        </ul></li>
      <li parentName="ul">{`Unciv - öppet Civilization: `}<ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.gamingonlinux.com/2022/12/unciv-the-open-source-remake-of-civilization-v-is-heading-to-steam/"
            }}>{`https://www.gamingonlinux.com/2022/12/unciv-the-open-source-remake-of-civilization-v-is-heading-to-steam/`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://github.com/yairm210/Unciv"
            }}>{`https://github.com/yairm210/Unciv`}</a></li>
        </ul></li>
    </ul>
    <h2>{`Kort och gott`}</h2>
    <ul>
      <li parentName="ul">{`10%+ Linux i Indien: `}<a parentName="li" {...{
          "href": "https://www.reddit.com/r/linux/comments/zbiyoc/linux_market_share_is_now_almost_10_in_india/"
        }}>{`https://www.reddit.com/r/linux/comments/zbiyoc/linux_market_share_is_now_almost_10_in_india/`}</a></li>
    </ul>
    <h2>{`Kontakta oss`}</h2>
    <ul>
      <li parentName="ul">{`Hemsida: `}<a parentName="li" {...{
          "href": "https://trevligmjukvara.se"
        }}>{`https://trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Mail: `}<a parentName="li" {...{
          "href": "mailto:kontakt@trevligmjukvara.se"
        }}>{`kontakt@trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`YouTube: `}<a parentName="li" {...{
          "href": "https://www.youtube.com/channel/UCRVmpkj-XM6UhUCjGiL3hhQ"
        }}>{`Trevlig Mjukvara`}</a></li>
      <li parentName="ul">{`Twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`Telegram: `}<a parentName="li" {...{
          "href": "https://t.me/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
      <li parentName="ul">{`Mastodon: `}<a parentName="li" {...{
          "href": "https://fikaverse.club/@trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`Matrix: `}<a parentName="li" {...{
          "href": "https://matrix.to/#/%23TrevligMjukvara:matrix.org"
        }}>{`#TrevligMjukvara`}</a></li>
      <li parentName="ul">{`GitHub: `}<a parentName="li" {...{
          "href": "https://github.com/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
      <li parentName="ul">{`Alex: `}<a parentName="li" {...{
          "href": "https://mastodon.online/@_alexander_"
        }}>{`@`}<em parentName="a">{`alexander`}</em></a></li>
      <li parentName="ul">{`Seb: `}<a parentName="li" {...{
          "href": "https://social.gelotte.dev/@seb"
        }}>{`@seb`}</a></li>
      <li parentName="ul">{`Seb på twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/Kikkusrikkus"
        }}>{`@Kikkusrikkus`}</a></li>
      <li parentName="ul">{`Donationer: `}<a parentName="li" {...{
          "href": "https://liberapay.com/TrevligMjukvara/donate"
        }}>{`https://liberapay.com/TrevligMjukvara/donate`}</a></li>
    </ul>
    <h2>{`Tack till`}</h2>
    <ul>
      <li parentName="ul">{`Ljudeffekter från `}<a parentName="li" {...{
          "href": "https://www.zapsplat.com/"
        }}>{`https://www.zapsplat.com/`}</a></li>
      <li parentName="ul">{`Musik från `}<a parentName="li" {...{
          "href": "https://filmmusic.io"
        }}>{`https://filmmusic.io`}</a><ul parentName="li">
          <li parentName="ul">{`Pixelland by Kevin MacLeod`}<ul parentName="li">
              <li parentName="ul">{`Link: `}<a parentName="li" {...{
                  "href": "https://filmmusic.io/song/4222-pixelland"
                }}>{`https://filmmusic.io/song/4222-pixelland`}</a></li>
            </ul></li>
          <li parentName="ul">{`NewsSting by Kevin MacLeod`}<ul parentName="li">
              <li parentName="ul">{`Link: `}<a parentName="li" {...{
                  "href": "https://filmmusic.io/song/4124-newssting"
                }}>{`https://filmmusic.io/song/4124-newssting`}</a></li>
            </ul></li>
          <li parentName="ul">{`Toccata and Fugue in D Minor by Kevin MacLeod`}<ul parentName="li">
              <li parentName="ul">{`Link: `}<a parentName="li" {...{
                  "href": "https://filmmusic.io/song/4533-toccata-and-fugue-in-d-minor"
                }}>{`https://filmmusic.io/song/4533-toccata-and-fugue-in-d-minor`}</a></li>
            </ul></li>
          <li parentName="ul">{`Beautiful World by Rafael Krux`}<ul parentName="li">
              <li parentName="ul">{`Link: `}<a parentName="li" {...{
                  "href": "https://filmmusic.io/song/5616-beautiful-world-"
                }}>{`https://filmmusic.io/song/5616-beautiful-world-`}</a></li>
            </ul></li>
          <li parentName="ul">{`Dreamy Flashback by Kevin MacLeod`}<ul parentName="li">
              <li parentName="ul">{`Link: `}<a parentName="li" {...{
                  "href": "https://filmmusic.io/song/3679-dreamy-flashback"
                }}>{`https://filmmusic.io/song/3679-dreamy-flashback`}</a></li>
            </ul></li>
          <li parentName="ul">{`Black Knight by Rafael Krux`}<ul parentName="li">
              <li parentName="ul">{`Link: `}<a parentName="li" {...{
                  "href": "https://filmmusic.io/song/5617-black-knight-"
                }}>{`https://filmmusic.io/song/5617-black-knight-`}</a></li>
            </ul></li>
          <li parentName="ul">{`Pixel Peeker Polka - Faster by Kevin MacLeod`}<ul parentName="li">
              <li parentName="ul">{`Link: `}<a parentName="li" {...{
                  "href": "https://filmmusic.io/song/4220-pixel-peeker-polka---faster"
                }}>{`https://filmmusic.io/song/4220-pixel-peeker-polka---faster`}</a></li>
            </ul></li>
          <li parentName="ul">{`Backbay Lounge by Kevin MacLeod`}<ul parentName="li">
              <li parentName="ul">{`Link: `}<a parentName="li" {...{
                  "href": "https://filmmusic.io/song/3408-backbay-lounge"
                }}>{`https://filmmusic.io/song/3408-backbay-lounge`}</a></li>
            </ul></li>
          <li parentName="ul">{`Heroic Adventure by Rafael Krux`}<ul parentName="li">
              <li parentName="ul">{`Link: `}<a parentName="li" {...{
                  "href": "https://filmmusic.io/song/5885-heroic-adventure"
                }}>{`https://filmmusic.io/song/5885-heroic-adventure`}</a></li>
            </ul></li>
          <li parentName="ul">{`Licens för alla: CC BY 4.0: `}<a parentName="li" {...{
              "href": "https://filmmusic.io/standard-license"
            }}>{`https://filmmusic.io/standard-license`}</a></li>
        </ul></li>
      <li parentName="ul">{`Grafik och font i loggan:`}<ul parentName="li">
          <li parentName="ul">{`Ok-emoji: emojione version 2.2.7, `}<a parentName="li" {...{
              "href": "https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg"
            }}>{`https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg`}</a>{` (CC BY 4.0)`}</li>
          <li parentName="ul">{`Font: Sulphur Point av Dale Sattler, `}<a parentName="li" {...{
              "href": "https://fonts.google.com/specimen/Sulphur+Point"
            }}>{`https://fonts.google.com/specimen/Sulphur+Point`}</a>{` (OFL)`}</li>
        </ul></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      